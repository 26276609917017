import '../../index.css'
import './gameboardPageStyles.css'
import CompetencyBoard from '../../components/CompetencyBoard/CompetencyBoard'
import { useEffect, useState, useContext } from 'react'
import { CardContext } from '../../context/CardContext'
import { getCompanyByDomain } from '../../dataServices/companies'
import { useDispatch } from 'react-redux'
import { replicateCompany } from '../../store/slices/companySlice'
import { clinicListReplacement } from '../../store/slices/lessonlyClinicSlice'
import { getCards } from '../../dataServices/cards'
import PopUp from '../../components/PopUp/PopUp'
import ModularButton from '../../components/ModularButton/ModularButton'
import { useNavigate } from 'react-router-dom'
import { getAllClinics } from '../../dataServices/lessonlyClinics'
import { ColorContext } from '../../context/colorContext'

const CardPage = () =>{
    const {mainDeck, cardList, setCardList} = useContext(CardContext)
    const {tallyUp} = useContext(ColorContext)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [buttonVisibility, setButtonVisibility] = useState(false)
    function navigateCardSelection(){
        tallyUp()
        navigate('/cardselectionpage')
    }


    
        //this useEffect should not be necessary in the final version, 
        //as this should be populated by the entry page, once the order of 
        //events is corrected after development on this page is finished.
        useEffect(()=>{
            async function getCompany(){
                try{
                    const companyResult = await getCompanyByDomain("roy.roseborne@corteva.com")
                    if(companyResult){
                        dispatch(replicateCompany(companyResult))
                        try{
                            const cardResult = await getCards(companyResult._id)
                            if(cardResult){
                                setCardList(cardResult)
                                try{
                                    const result =  await getAllClinics()
                                    if(result){
                                        dispatch(clinicListReplacement(result))
                                    }
                                }catch(e){
                                    alert(e)                                
                                }
                            }
                        }catch(e){
                            alert(e)
                        }
                    }
                }catch(e){
                    alert(e)
                }
            }

            getCompany()
            return ()=>{}
        },[])

        useEffect(()=>{
            let BreakException = {}
            try{
                mainDeck.forEach(entry =>{
                    if(entry.skill==="click here!"){
                        setButtonVisibility(false)
                        throw BreakException
                    }
                    else{
                        setButtonVisibility(true)
                    }
                })
            }
            catch(e){
                if(e !== BreakException) throw e;
            }
        
            return () =>{
                return null
            }
        }, [cardList])
        //the dependancy array MUST have cardList, NOT mainDeck

    return(
        <div className="page-container scroll-limit">
            <h1 className='page-title'>Select Your 10 Competencies</h1>
            <p className='instruction-text'>Click the card tops to search skills. Click on <i className="fa-solid fa-chevron-left" > </i><i className="fa-solid fa-chevron-right" ></i> to adjust your development level.</p>
            <PopUp/>

            <div className='gameboard-container'>
                <CompetencyBoard />
            </div>
            <ModularButton visibility={buttonVisibility} func={navigateCardSelection}>
                submit
            </ModularButton>
        </div>
    )
}

export default CardPage