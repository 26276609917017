import axios from 'axios'
import { emailUrl } from '../utils/urlReferences'

export async function sendEmailConfirmation(dataObject){

    try{
        const response = await axios.post(`${emailUrl}/assessments/send`, dataObject)
        if(response){
            return response.data
        }
    } catch(e){
        if(e.status === 400){
            throw new Error(e.response.data)
        } else{
            return e
        }
    }
}

