import ModularButton from '../ModularButton/ModularButton'
import SelectionOption from './SelectionOption'
import Loader from '../Loader/Loader'
import './selectionStyles.css'
import { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assessmentClinicListChange } from '../../store/slices/assessmentSlice'
import { useNavigate } from 'react-router-dom'

import { postAndEditAssessment } from '../../dataServices/assessments'
import { sendEmailConfirmation } from '../../dataServices/emails'
import { ColorContext } from '../../context/colorContext'

const SelectionSlide = ({heading,  monthNumber, selectedNumber, targetArray, slideFunc}) =>{
    const [selectedElement, setSelectedElement] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const assessment = useSelector(state => state.assessmentState.assessment)
    const employee = useSelector(state => state.employeeState.employee)
    const company = useSelector(state => state.companyState.company)
    const season = useSelector(state => state.seasonState.season)
    const clinics = useSelector(state => state.clinicState.lessonly.clinics)
    const {topColor} = useContext(ColorContext)

    function filterClinics(arr, num){
        const filtered = arr.filter(entry => entry.month === num)
        return filtered
    }

    async function makeSelection(){
        const temp = assessment.clinicList
        const final = [...temp, selectedElement]
        dispatch(assessmentClinicListChange(final))
        if(slideFunc){
            setIsSelected(true)
            slideFunc(monthNumber)
        } else{
            try{
                setIsLoading(true)
                const result = await postAndEditAssessment({
                    employee_id: employee._id,
                    company_id: company._id,
                    season_id: season._id,
                    clinicList: final
                })
                if(result){
                    let modifiedArray = []
                    for (let i = 0; i < final.length; i++) {
                        clinics.forEach(entry =>{
                            if(entry._id === final[i]){
                                modifiedArray.push({
                                    title: entry.title,
                                    monthName: entry.monthName,
                                    link: entry.link
                                })
                            }
                        })
                      }

                          try{
                            const result = await sendEmailConfirmation({
                                email: employee.email,
                                firstName : employee.firstName,
                                clinicList: modifiedArray
                            })
                            if(result){
                                setIsLoading(false)
                                setIsSelected(true)
                                navigate('/confirmationpage')
                            }
                        }catch(e){
                            alert(e)
                            setIsLoading(false)
                        }
                    
                }
            }catch(e){
                console.log(e)
                alert(e)
                setIsLoading(false)

            }
        }
    }


    const list = filterClinics(targetArray, monthNumber)

   
    return( 
        <div>
            <div className='selection-slide'>
                    <div className='slide-header-container'>
                        <h3 className='selection-heading'>{heading}</h3>
                        {topColor !== undefined && 
                            <div className='key-container'>
                                <span>suggested</span>
                                <div className='selection-checkbox suggested'></div>
                            </div> 

                        }
                    </div>
                    {list.length > 0 && !isSelected && (selectedNumber === monthNumber) &&
                        list.map(entry => <SelectionOption 
                            key={list.indexOf(entry)} 
                            id={entry._id}
                            clinicTitle={entry.title}
                            clinicDescription={entry.description}
                            clinicTakeAways={entry.take_aways}
                            setter={setSelectedElement}
                            selected={selectedElement}
                            />
                        )
                    }

            </div>
            <ModularButton classAddition="selection-advance" visibility={selectedElement !== "" && !isLoading && !isSelected} func={makeSelection}>select</ModularButton>
            {isLoading && <Loader />}
        </div>
    )
}

export default SelectionSlide
