import axios from 'axios'
import { url } from '../utils/urlReferences'


export async function getHBContact(email){
 
    try{
        const response = await axios.post(`${url}/hb/getcontact`, {email: email})
        if(response){
            //hubspot returns a deeply nested array "results",
            //a child of the response's data object. Do a check if the array has an
            //element or not.

            return response.data.results
        }
    } catch(e){
        return e
    }
}

export async function postHBContact(contactInfo){
 
    try{
        const response = await axios.post(`${url}/hb/postcontact`, contactInfo)
        if(response){
            //hubspot returns a deeply nested array "results",
            //a child of the response's data object. Do a check if the array has an
            //element or not.
            return response
        }
    } catch(e){
        return e
    }
}

export async function updateHBContact(contactInfo){

    try{
        const response = await axios.put(`${url}/hb/updatecontact`, contactInfo)
        if(response){

            //hubspot returns a deeply nested array "results",
            //a child of the response's data object. Do a check if the array has an
            //element or not.
            return response
        }
    } catch(e){
        return e
    }
}
