import './selectionStyles.css'

const SelectionOption = ({clinicTitle, clinicDescription, clinicTakeAways, id, setter, selected, isSuggested}) =>{
    
    // function capitalizeAllWords(word){
    //     return word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    // }

    return(
        <div className={`selection-option ${isSuggested && 'suggested'}`} onClick={()=>{
            if(selected === id){
                setter("")
            }else{
                setter(id)
            }
        }}>
            <div className='selection-option-top'>
                <p className='selection-title'>{clinicTitle}</p>
                
                {
                    selected === id? 
                    <div className='selection-checkbox selected'></div> 
                    :
                    <div className='selection-checkbox'></div> 
                }
            </div>
            {
                selected === id? 
                <div className='selection-option-bottom open'>
                <p className='selection-caption'>Description</p>
                <p className='selection-description'>{clinicDescription}</p>
                <p className='selection-caption'>Takeaways</p>
                <ul className='selection-list'>
                    {clinicTakeAways.length > 0 &&
                    clinicTakeAways.map(entry => 
                        <li key={clinicTakeAways.indexOf(entry)} className='selection-list-item'>{entry}</li>
                    )}
                </ul>
            </div>
            :
            <div className='selection-option-bottom'>
                <p className='selection-caption'>Description</p>
                <p className='selection-description'>{clinicDescription}</p>
                <p className='selection-caption'>Takeaways</p>
                <ul className='selection-list'>
                    {clinicTakeAways.length > 0 &&
                    clinicTakeAways.map(entry => 
                        <li key={clinicTakeAways.indexOf(entry)} className='selection-list-item'>{entry}</li>
                    )}
                </ul>
            </div>
            }
            
        </div>

    )
}

export default SelectionOption
