import {useContext, useState, } from 'react'
import {CardSkillInput, SearchContainer} from './cardOptionStyles'
import CardSearchList from './CardSearchList'
import { useSelector, useDispatch } from "react-redux"
import { setSkillPopUp } from '../../store/slices/popUpSlice'
import { CardContext } from '../../context/CardContext'

const CardOptions = ({indx, vis, val, side}) =>{
    
    const [toggle, setToggle] = useState(false)
    const [value, setValue] = useState("")
    const dispatch = useDispatch()
    const company = useSelector(state => state.companyState.company)
    const {mainDeck, setMainDeck, cardList, setCardList} = useContext(CardContext) 



    const designateValue = (developmentStage) =>{
        let val
        switch(developmentStage){
            case 'developing' :
                val = 4
                break
            case 'intermediate' :
                val = 3
                break
            case 'advanced' :
                val = 2
                break
            case 'mastery':
                val = 1
                break
            default: val = 4
                break
        }

        return val
}

    const updateSelections = (target) =>{
    
        
        const modified = cardList.map(entry =>{
            if(entry.skill === mainDeck[indx].skill){
                return {...entry, selected: false}
            }
            else return entry
        })

        

        cardList.forEach(entry =>{
            if(entry.skill === target){
     

                let cloneArray = mainDeck

                if(indx < 2){
                     cloneArray.splice(indx, 1, {...entry, devStage: mainDeck[indx].devStage , value: (designateValue(mainDeck[indx].devStage)*3), selected: true, gameChanger: true})
                }
                else{
                    cloneArray.splice(indx, 1, {...entry, devStage: mainDeck[indx].devStage , value: designateValue(mainDeck[indx].devStage), selected: true, gameChanger: false})
                }
               
                setMainDeck(cloneArray)
            }
        })


       
        setCardList(modified.map(entry =>{
            if(entry.skill === target){
                return {...entry, selected: true}
            }
            else return entry
        }))
    }



    return(
            
            <SearchContainer 
                vis={vis}
                bottomcolor = {company !== undefined && company.bottomColor}
                accentcolor = {company !== undefined && company.accentColor}
                titlecolor = {company !== undefined && company.titleColor}
             >
                <CardSkillInput
                    vis={vis} 
                    val={value}
                    placeholder="search skills"
                    side={side} 
                    onChange={(e)=>{setValue(e.target.value) ; setToggle(true)}}
                    titlecolor = {company !== undefined && company.titleColor}
                    accentcolor = {company !== undefined && company.accentColor}
                    bottomcolor = {company !== undefined && company.bottomColor}
                    onBlur={()=>{
                        window.scrollTo(0,0);
                    }}
                />

                
                {
                    toggle &&
                    
                    <CardSearchList
                        vis={vis}
                        value={value}
                        list = {cardList.length > 0 && cardList}
                        //target is a general term, not a variable in this case
                        //same as  'e'. It's only written like this because
                        //to match the arrow function format 
                        setSkill = {(target)=>{updateSelections(target)}}
                        toggle = {toggle}
                        setToggle = {setToggle}
                        indx = {indx}
                        accentcolor = {company !== undefined && company.accentColor}
                    />

                }
                <p onClick={()=>{dispatch(setSkillPopUp(false))}}>cancel</p>
            </SearchContainer>
                


    

    )
}

export default CardOptions