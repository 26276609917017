import styled, {css} from 'styled-components'

const gameChangerPosition = (value) =>{

    let val

    switch(value){
        case 3: val = "75%"
        break
        case 6: val = "50%"
        break
        case 9: val = "25%"
        break
        case 12: val =  "0%"
        break
        default: val = "0%"
        break
    }

    

    return val
}

const valuePosition = (value) =>{

    let val
    switch(value){
        case 1: val = "75%"
        break
        case 2: val = "50%"
        break
        case 3: val =  "25%"
        break
        case 4: val = "0%"
        break
        default: val = "0%"
    }

    

    return val
}



  


export const CardPair = styled.div`
    position: absolute;
    left: ${props => !props.gamechanger? valuePosition(props.val) : gameChangerPosition(props.val)};
    top: 0; 
    bottom: 0; 
    transition: left 0.4s ease;
    display: flex;
    width: 25%;
    flex-direction: ${props => props.val < 3? "row-reverse" : "row"};
    overflow: hidden;
    h4{
        transition: none;
    }
`

export const ReversableContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.val < 3? "row-reverse" : "row"};
    /* width: 50%; */
`


const colorSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "blue": val = `dodgerblue`
        break
        case "red": val = `firebrick`
        break
        case "orange": val = `tomato`
        break
        case "green": val = `forestgreen`
        break
        case "yellow": val = `gold`
        break
        default: val = "white"
    }
    return val
}

const fontSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "yellow": val = `black`
        break
        default: val = "white"
    }
    return val
}

export const Card = styled.div`
    background: ${props => props.companycardcolor};
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${props => props.accentcolor};
    /* margin-top: auto; 
    margin-bottom: auto;  */
    min-height: 100px;
    *{
        font-size: 0.7rem;
    }
    
`

const sectionStyle= css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const CardTop = styled.div`
    ${sectionStyle};
    background: ${props => props.companycardcolor};
    *{color: ${props => props.companycardfontcolor};};
    height: 42%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    padding: 0.25rem;
    cursor: pointer;
`

export const CardDivider = styled.div`
    background: white;
    height: 2%;
`

export const CardBottom = styled.div`
    ${sectionStyle};
    background-color: ${props =>  colorSwapper(props.pathcolor)};
    color: ${props =>  fontSwapper(props.pathcolor)};
    height: 56%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: space-between;
    padding: 1.5vh 0;
    h4{
        color: ${props =>  fontSwapper(props.pathcolor)};
    }
   
`
export const CardBottomLPath = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
`




export const SkillReselect = styled.div` 
       flex: 1 1 0px;
`
