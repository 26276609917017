import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    company: {territories: {
            designation: "",
            locations: []
        },
        name: "",
        emailDomains: [
        ],
        industryYears: []
        }
}

export const companySlice = createSlice({
    name: "company",
    initialState,
    reducers:{
        replicateCompany: (state, action)=>{
            state.company = action.payload
        }
    }
})

export const {
    replicateCompany
} = companySlice.actions

export default companySlice.reducer