import './modularDropDownStyles.css'
import { useDispatch} from 'react-redux'


const ModularDropDown = ({list, setter, defaultOptTitle, employeeCurrent, hasLabel, label}) =>{
    const dispatch = useDispatch()

    return(
        <div>
        {hasLabel && <p className="modular-input-label">{label}</p>}
        <select className='modular-drop-down-container' onChange={(e)=>{dispatch(setter(e.target.value))}} defaultValue={employeeCurrent !== ""? employeeCurrent : defaultOptTitle}>
        <option  hidden>{defaultOptTitle}</option>
        
        {   
            list.map(entry =>{
              
                    return(
                        <option key={entry} value={entry}>
                        {entry}
                     </option>
                    )
                
            })
        }
    </select>
        </div>
    )
}

export default ModularDropDown