import './App.css';
import {BrowserRouter, Routes, Route,} from 'react-router-dom'
import EntryPage from './pages/EntryPage/EntryPage';
import ErrorPage from './pages/ErrorPage/ErrorPage'
import ContactPage from './pages/ContactPage/ContactPage';
import SelectionPage from './pages/SelectionPage/SelectionPage';
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import GameboardPage from './pages/GameboardPage/GameboardPage';
import CardSelectionPage from './pages/CardSelectionPage/CardSelectionPage';
import PathPage from './pages/PathPage/PathPage';

function App() {
  return (
    <BrowserRouter >
      <Routes>
          <Route path="/" element={<EntryPage/>}/>
          <Route path="/contactpage" element={<ContactPage/>}/>
          <Route path="/pathpage" element={<PathPage/>} />
          <Route path="/gameboardpage" element={<GameboardPage/>}/>
          <Route path="/selectionpage" element={<SelectionPage/>}/>
          <Route path="/cardselectionpage" element={<CardSelectionPage/>} />
          <Route path="/confirmationpage" element={<ConfirmationPage />}/>
          <Route path="*" element={<ErrorPage/>}/>
      </Routes>  
  </BrowserRouter>
  );
}

export default App;
