import {
    CardTray, 
    CardSlot1,
    CardSlot2,
    CardSlot3,
    CardSlot4
} from "./cardSetStyles"
import CardComponent from '../CardComponent/CardComponent'
import {useState, useContext} from 'react'
import { useSelector } from "react-redux"
import { CardContext } from "../../context/CardContext"

const CardSet = ({bg_Color, skill, devStage, val_, ind_, entry}) =>{
    const company = useSelector(state => state.companyState.company)
    const {mainDeck, setMainDeck} = useContext(CardContext)
    const [rightLock, setRightLock] =  useState(false)
    const [leftLock, setLeftLock] =  useState(false)

    const lockTimer = (lockFunction, timeInterval) =>{
        setTimeout(()=>{
            lockFunction(false)
        }, timeInterval)
    }

    const determinePath = (value, gameChangerStat=false) =>{
        let val
        if(gameChangerStat){
            switch(value){
                case 3: val = "mastery"
                break
                case 6: val = "advanced"
                break
                case 9: val = "intermediate"
                break
                case 12: val = "developing"
                break
                default: val ="developing"
                break
            }
            return val
        }
        else{
            switch(value){
                case 1: val = "mastery"
                break
                case 2: val = "advanced"
                break
                case 3: val = "intermediate"
                break
                case 4: val = "developing"
                break
                default: val ="developing"
                break
            }
            return val
        }
    }

    const decreaseVal = () =>{
        if(!rightLock){
            setRightLock(true)
            let tempArray = mainDeck.map(item =>{
                if(mainDeck.indexOf(item) === ind_){
                    if(mainDeck.indexOf(item) < 2){
                        if(item.value > 3){
                            return {...item, value: item.value - 3,  devStage: determinePath(item.value-3, item.gameChanger) }
                        }
                    }
                    
                    if(item.value > 1){
                        return {...item, value: item.value - 1, devStage: determinePath(item.value-1)}
                    }
                    return item
                }
                else return item
            })
            setMainDeck(tempArray)
            lockTimer(setRightLock, 500)
        }
    }

    const increaseVal = () =>{
        if(!leftLock){
            setLeftLock(true)
            let tempArray = mainDeck.map(item =>{
                if(mainDeck.indexOf(item) === ind_){
                    if(mainDeck.indexOf(item) < 2){
                        if(item.value < 12){
                            return {...item, value: item.value + 3,  devStage: determinePath(item.value+3, item.gameChanger) }
                        }
                    }
                    
                    if(item.value < 4){
                        return {...item, value: item.value + 1, devStage: determinePath(item.value+1)}
                    }
                    return item
                }
                else return item
            })
            setMainDeck(tempArray)
            lockTimer(setLeftLock, 500)
        }
    }

    return(
        <CardTray
            bottomcolor = {company !== undefined && company.bottomColor}

        >
            <CardComponent entry={entry} bg_Color={bg_Color} val_={val_} skill={skill} devStage={devStage} indx_={ind_}/>
            <CardSlot1
                accentcolor = {company !== undefined && company.accentColor}
                bottomcolor = {company !== undefined && company.bottomColor}
                onClick={increaseVal}
            >
                <p>developing</p>
                <i className="fa-solid fa-chevron-left" ></i>
            </CardSlot1>
            <CardSlot2
                accentcolor = {company !== undefined && company.accentColor}
                bottomcolor = {company !== undefined && company.bottomColor}
            >
                <p>intermediate</p>
            </CardSlot2>
            <CardSlot3
                accentcolor = {company !== undefined && company.accentColor}
                bottomcolor = {company !== undefined && company.bottomColor}
            >
                <p>advanced</p>
            </CardSlot3>
            <CardSlot4
            accentcolor = {company !== undefined && company.accentColor}
            bottomcolor = {company !== undefined && company.bottomColor}
            onClick={decreaseVal}
            >   
                <p>mastery</p>
                <i className="fa-solid fa-chevron-right" ></i>
            </CardSlot4>
        </CardTray>
    )
}

export default CardSet