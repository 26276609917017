import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hbContact: {
            firstName : "",
            lastName: "",
            company: "",
            email: "",
            industryYears: "",
            phoneNumber: "",
            contactId: ""
        }
}

export const hbContactSlice = createSlice({
    name: "hbContact",
    initialState,
    reducers:{
        hbReplicate: (state, action)=>{
            state.hbContact = action.payload
        },
        hbFirstNameChange:(state, action)=>{
            state.hbContact.firstName = action.payload
        },
        hbLastNameChange:(state, action)=>{
            state.hbContact.lastName = action.payload
        },
        hbEmailChange:(state, action)=>{
            state.hbContact.email = action.payload
        },
        hbCompanyChange:(state, action)=>{
            state.hbContact.company = action.payload
        },
        hbIndustryYearsChange:(state, action)=>{
            state.hbContact.industryYears = action.payload
        },
        hbPhoneNumberChange:(state, action)=>{
            state.hbContact.phoneNumber = action.payload
        },
        hbContactIdChange:(state, action)=>{
            state.hbContact.contactId = action.payload
        },
    }
})

export const {
    hbReplicate,
    hbfirstNameChange, 
    hblastNameChange,
    hbEmailChange,
    hbCompanyChange,
    hbindustryYearsChange,
    hbphoneNumberChange,
    hbContactIdChange,
} = hbContactSlice.actions

export default hbContactSlice.reducer