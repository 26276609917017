import {useState, createContext} from 'react'

const CardContext = createContext()

function CardContextProvider({children}){
    const [mainDeck, setMainDeck] = useState([
        {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
        {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4},
        {skill:"click here!", devStage:"developing", value: 4}
    ])
    const [cardList, setCardList] = useState([])
    return(
        <CardContext.Provider value={{
            mainDeck,
            setMainDeck,
            cardList,
            setCardList
        }}>
            {children}
        </CardContext.Provider>
    )
}

export {CardContext, CardContextProvider}