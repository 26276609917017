import './errorComponentStyles.css'

const ErrorComponent = ({message, visiblity, func}) =>{
    return(
        visiblity && 
    <div className='error-component-container'>
        <p className='error-component-message'>{message}</p>
        {/* <button className='error-component-button' onClick={func}>close</button> */}
    </div>
    )
}

export default ErrorComponent