import {CardConcealableOption, CardSearchListContainer, StyledItem} from '../CardOptions/cardOptionStyles'
import { setSkillPopUp } from '../../store/slices/popUpSlice'
import { useSelector, useDispatch } from 'react-redux'

const CardSearchList = ({value, list, setSkill, toggle, setToggle, vis}) =>{
    
    const company = useSelector(state => state.companyState.company)
    const dispatch = useDispatch()


    if(value){
        const filteredList = list.filter(entry => entry.skill?.toString().toLowerCase().startsWith(value.toLowerCase()))

        if(filteredList.length > 0){
            return(
                toggle &&
                (
                    <CardSearchListContainer
                     vis={vis}
                     titleColor = {company !== undefined && company.titleColor}
                     bottomColor = {company !== undefined && company.bottomColor}
                     accentColor = {company !== undefined && company.accentColor}

                    >
                        {
                            filteredList.map(entry => 
                                <CardConcealableOption 
                                        key={entry.skill} 
                                        vis={entry.selected}
                                        onClick={()=>{setSkill(entry.skill); setToggle(false); dispatch(setSkillPopUp(false))}}
                                        accentColor = {company !== undefined && company.accentColor}
                                        titleColor = {company !== undefined && company.titleColor}
                                    >
                                        {entry.skill}
                                </CardConcealableOption>  )
                        }
                    </CardSearchListContainer>
                )   
            )
        }

        return(
            <CardSearchListContainer>
                <StyledItem>
                    Not found
                </StyledItem>
            </CardSearchListContainer>
        )
    }

    
    
    return null
    
}


export default CardSearchList