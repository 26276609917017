import {PopUpInner} from '../PopUp/popUpStyles'
import CardOptions from '../CardOptions/CardOptions'
import { useSelector, useDispatch } from "react-redux"
import { setSkillPopUp} from '../../store/slices/popUpSlice'
import { useContext } from 'react'
import { CardContext } from '../../context/CardContext'

const SkillModal = () =>{
    const dispatch = useDispatch()
    const popUp = useSelector(state => state.popUpState.popUp)
    const {skillPopUp, currentPopUp} = popUp 
    const {mainDeck} = useContext(CardContext)



    return(
        <PopUpInner vis={skillPopUp}>
            <div style={{height: "10vh"}} onClick={()=>{dispatch(setSkillPopUp(false))}}/>

                {
                        mainDeck.map(entry =>{
                        
                                
                                if(mainDeck.indexOf(entry) === currentPopUp){
                                    return(
                                        <CardOptions
                                            key={mainDeck.indexOf(entry)} 
                                            val={entry.value}
                                            indx={mainDeck.indexOf(entry)}
                                            vis={true}
                                        />
                                    )
                                }
                        
                        })
                }
            <div style={{height: "100%"}} onClick={()=>{setSkillPopUp(false)}}/>
        </PopUpInner>
    )
}

export default SkillModal