import '../../index.css'
import './cardSelectionPageStyles.css'
import CardSelectionModule from '../../components/Selection/CardSelectionModule'


const SelectionPage = () =>{

    return(
        <div className="page-container scroll-limit">
            <h1 className='page-title'>Select Your 5 Clinic Topics</h1>
            <p className='instruction-text'>Suggestions are based on the results of your card sort but are optional. Feel free to choose as you wish!</p>
            <div className='selection-module-container'>
                <CardSelectionModule />
            </div>
        </div>
    )
}

export default SelectionPage