import {
    Card, 
    CardPair,
    CardTop,
    CardDivider,
    CardBottom,
    CardBottomLPath
} from './cardComponentStyles'
import {useSelector, useDispatch} from 'react-redux'
import { setSkillPopUp, setCurrentPopUp } from '../../store/slices/popUpSlice'


const CardComponent = ({bg_Color, entry, val_, skill, devStage, indx_}) =>{
    const dispatch = useDispatch()

    const company = useSelector(state => state.companyState.company)
    const popUp = useSelector(state => state.popUpState.popUp)
    const {skillPopUp} = popUp



    return(
        // val_ > 2?
        
        <CardPair val={val_} gamechanger={entry.gameChanger}>

            <Card  
                accentcolor = {company !== undefined && company.accentColor} 
                >
                <CardTop
                      companycardcolor = {company !== undefined && company.companyCardColor} 
                      companycardfontcolor = {company !== undefined && company.companyCardFontColor}
                      onClick={()=>{
                        dispatch(setCurrentPopUp(indx_))
                        if(!skillPopUp){
                            dispatch(setSkillPopUp(true))
                        }else{
                            dispatch(setSkillPopUp(false))
                        }
                      }}
                      >
                    <h4 >{skill.length > 12? skill.slice(0, 10)+"...": skill}</h4>
                </CardTop>
                <CardDivider/>
                <CardBottom 
                    pathcolor={entry.learningPath} 
                    companycardfontcolor = {company !== undefined && company.companyCardFontColor}
                    
                    >
                    <CardBottomLPath
                        pathcolor={entry.learningPath} 
                        
                        >
                        {
                            <p style={{fontWeight: "700"}}>{entry.learningPath}</p>
                        }
                        <p>learning path</p>
                    </CardBottomLPath>
                    <h4>{devStage}</h4>
                </CardBottom>
            </Card>



        </CardPair>

        

        

        

        

        
    )
}

export default CardComponent