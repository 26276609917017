import { createSlice } from "@reduxjs/toolkit";
//This file is not being used. Basing the logic
//on the mainDeck property of the state
//didn't allow for the manipulation of a copy array
//since it's read-only, and the copy seemed to take that 
//read-only status.

const initialState = {
    cards:{
        cardList:[],
        mainDeck: [
            {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
            {skill:"click here!", devStage:"developing", value: 12, gameChanger: true},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4},
            {skill:"click here!", devStage:"developing", value: 4}
        ],
        pseudoDeck:[]
    }
}

export const cardSlice = createSlice({
    name: "cards",
    initialState,
    reducers:{
        cardListReplace: (state, action)=>{
            state.cards.cardList = action.payload
        },
        mainDeckReplace:(state, action)=>{
            state.cards.mainDeck = action.payload
        },
        pseudoDeckReplace:(state, action)=>{
            state.cards.pseudoDeck = action.payload
        }
    }
})

export const {
    cardListReplace,
    mainDeckReplace,
    pseudoDeckReplace
} = cardSlice.actions

export default cardSlice.reducer