import "./modularInputStyles.css"

const ModularInput = ({typeStr, value, setter, marginBottom, hasLabel, label, placeHolder}) =>{
    return(
        <div>
        {hasLabel && <p className="modular-input-label">{label}</p>}
        <input className="modular-input-container" type={typeStr}  value={value} onChange={e => setter(e.target.value)}
            style={{marginBottom: marginBottom}}
            placeholder={placeHolder}
            onBlur={()=>{
                window.scrollTo(0,0);
            }}
            />
        </div>
    )
}

export default ModularInput