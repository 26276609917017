import axios from 'axios'
import { url } from '../utils/urlReferences'

export async function getCurrentSeason(){

    try{
        const response = await axios.get(`${url}/seasons/latest`)
        if(response){
            return response.data
        }
    } catch(e){
            return e
    }
}
