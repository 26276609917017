import {useState, useEffect, createContext, useContext} from 'react'
import { CardContext } from './CardContext'
import { useSelector } from 'react-redux'

const ColorContext = createContext()

function ColorContextProvider({children}){
    const [finalResults, setFinalResults] = useState(undefined)
    const [colorArray, setColorArray] = useState([])
    const [topColor, setTopColor] = useState(undefined)

    const {mainDeck} = useContext(CardContext)

    const tallyUp = () =>{
        let placeholder = []
        let bp = 0
        let rp = 0
        let gp = 0
        let yp = 0
        let op = 0

        mainDeck.forEach(entry =>{
            // let modifier = 1
            if(mainDeck.indexOf(entry) < 2){
                // modifier = 3
            }
            switch(entry.learningPath){
                case "blue" :  bp = bp + (entry.value)
                break
                case "red" : rp = rp + (entry.value)
                break
                case "green" : gp = gp + (entry.value) 
                break
                case "yellow" : yp = yp + (entry.value)
                break
                case "orange" : op = op + (entry.value)
                break
                default: return null
            }

        })
        
        // placeholder = 
        // [
        //     {color: "blue", value: bp}, 
        //     {color: "red", value: rp}, 
        //     {color: "green", value: gp}, 
        //     {color: "yellow", value: yp},
        //     {color: "orange", value: op}
        // ]
        placeholder = [bp, rp, gp, yp, op]
        setColorArray(placeholder)
    }



useEffect(()=>{
    if(colorArray.length > 0){
        setFinalResults(determinePathResults(colorArray))
    }
},[colorArray])

const determinePathResults = (pathArray) =>{
    const determineColor = (val) =>{
        let colorString
        switch(pathArray.indexOf(val)){
            case 0 : colorString = "blue"
            break
            case 1 : colorString = "red"
            break
            case 2 : colorString = "green"
            break
            case 3 : colorString = "yellow"
            break
            case 4 : colorString = "orange"
            break
            default: colorString = "blue"
        }
        return colorString
    }

    let optionSet  = {}
    const firstPlace = Math.max(...pathArray)
    const firstColor = determineColor(firstPlace)
    const withoutFirst = pathArray.filter(entry => {
        if(entry !== firstPlace){
            return entry
        }
    })
    const secondPlace = Math.max(...withoutFirst)

    const secondColor = determineColor(secondPlace)
    optionSet = [
        {firstPlace: firstPlace, firstColor: firstColor},
        {secondPlace: secondPlace, secondColor: secondColor},
        {manual: true, colors: ["blue", "red", "green", "yellow", "orange"]}
    ]
    return optionSet
}


    //Watches final results, specifically for the first element in the array
    //which is an object that houses the first place score and color
    //and sets the topColor 
    useEffect(()=>{
        if(finalResults !== undefined){
            if(finalResults[0].firstColor !== 'yellow'){
                setTopColor(finalResults[0].firstColor)
            }else{
                setTopColor(finalResults[1].secondColor)
            }
        }
        
    },[finalResults])

    return(
        <ColorContext.Provider value={{
           tallyUp,
           finalResults,
           topColor
        }}>
            {children}
        </ColorContext.Provider>
    )
}

export {ColorContext, ColorContextProvider}