import {PopUpContainer} from './popUpStyles'
import SkillModal from '../SkillModal/SkillModal'


const PopUp = () =>{

    return(
        <PopUpContainer>
            <SkillModal />
        </PopUpContainer>
    )
}


export default PopUp