import '../../index.css'
import './contactPageStyles.css'
import ModularInput from '../../components/ModularInput/ModularInput'
import ModularDropDown from '../../components/ModularDropDown/ModularDropDown'
import ModularButton from '../../components/ModularButton/ModularButton'
import Loader from '../../components/Loader/Loader'
// import InputMask from "react-input-mask";
import { getHBContact, postHBContact, updateHBContact } from '../../dataServices/hubspot'
import { postAndUpdateEmployee} from '../../dataServices/employees'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    employeeIdChange,
    firstNameChange, 
    lastNameChange,
    emailChange,
    managerEmailChange,
    employeeTerritoryChange,
    industryYearsChange
} from '../../store/slices/employeeSlice'
import {hbContactIdChange} from '../../store/slices/hubspotSlice'


const ContactPage = () =>{

    const employee = useSelector(state => state.employeeState.employee)
    const company = useSelector(state => state.companyState.company)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    
    async function submitContactInfo(){
        setIsLoading(true)
        try{
            const result =  await postAndUpdateEmployee({
                firstName: employee.firstName,
                lastName: employee.lastName,
                email: employee.email,
                managerEmail: employee.managerEmail,
                employeeTerritory: employee.employeeTerritory,
                industryYears: employee.industryYears,
                // phoneNumber: employee.phoneNumber
            })
            if(result){
                if(result.upsertedId !== null){
                    dispatch(employeeIdChange(result.upsertedId))
                }
                try{
                    const hbResult = await getHBContact(employee.email)
                    if(hbResult.length > 0){
                        const contactId = hbResult[0].properties.hs_object_id
                        dispatch(hbContactIdChange(contactId))
                        try{
                            const editResult = await updateHBContact({
                                    firstName : employee.firstName,
                                    lastName: employee.lastName,
                                    company: company.name,
                                    email: employee.email,
                                    industryYears: employee.industryYears,
                                    // phoneNumber: employee.phoneNumber,
                                    contactId: contactId
                                })
                            if(editResult.status === 200){
                                navigate("/pathpage")
                            }
                        }catch(e){
                            setIsLoading(false)
                            alert(e)
                        }
                    }else{
                        try{
                            const hbResult = await postHBContact({
                                firstName : employee.firstName,
                                lastName: employee.lastName,
                                company: company.name,
                                email: employee.email,
                                industryYears: employee.industryYears,
                                // phoneNumber: employee.phoneNumber
                            })
                            if(hbResult){
                                setIsLoading(false)
                                navigate("/pathpage")
                            }
                        }catch(e){
                            setIsLoading(false)
                            alert(e)
                        }
                    }
                }catch(e){
                    setIsLoading(false)
                    alert(e)
                }
            }    
       
    
    } catch(e){
            setIsLoading(false)
            alert(e)
    }}

    return(
        //contact-container is in the contactPageStyles.css / page-container is from index.css
        <section className='page-container contact-container'>
            <h1 className="page-title">Contact Page</h1>
            <div className="contact-page-inner-container">
            <ModularInput typeStr="text" value={employee.firstName} setter={(e)=>dispatch(firstNameChange(e))} marginBottom={"2vmax"} hasLabel={true} label={"First Name"}/>
            <ModularInput typeStr="text" value={employee.lastName} setter={(e)=>dispatch(lastNameChange(e))} marginBottom={"2vmax"} hasLabel={true} label={"Last Name"}/>
            <ModularInput typeStr="email" value={employee.email} setter={(e)=>dispatch(emailChange(e))} marginBottom={"2vmax"} hasLabel={true} label={"Email"}/>
            <ModularInput typeStr="email" value={employee.managerEmail} setter={(e)=>dispatch(managerEmailChange(e))} marginBottom={"2vmax"} hasLabel={true} label={"Manager Email"}/>
            {/* <ModularInput typeStr="tel" value={employee.phoneNumber} setter={(e)=>dispatch(phoneNumberChange(e))} marginBottom={"4vmax"} hasLabel={true} label={"Phone Number"}/> */}

           {/* <label style={{ paddingLeft: '1vmax',
        marginBottom: '0.5vmax'}}> Phone Number</label> */}
            {/* <InputMask
            style={{ border: '0.25vmax solid black',
                padding: '1vmax 1.5vmax',
                borderRadius: '0.75vmax',
                marginBottom: '2vmax'}}
             mask="(999)-999-9999"
             value={employee.phoneNumber}
             onChange={(e)=>dispatch(phoneNumberChange(e.target.value))}
             placeholder='(###-###-####)'
            />    
 */}

 

            <div className='drop-down-container'>
                {company.territories.locations.length > 0 &&  <ModularDropDown  list={company.territories.locations} setter={employeeTerritoryChange} defaultOptTitle={company.territories.designation} employeeCurrent={employee.employeeTerritory} hasLabel={true} label={"Commercial Unit"}/>}
                {company.industryYears.length > 0 &&  <ModularDropDown  list={company.industryYears} setter={industryYearsChange} defaultOptTitle={"Years of Experience"} employeeCurrent={employee.industryYears !== "" && employee.industryYears} hasLabel={true} label={"Years In Industry"}/>}
            </div>
            
            {isLoading && <Loader/>}
            <ModularButton visibility={
                !isLoading &&
                employee.firstName !== "" &&
                employee.firstName !== null &&
                employee.lastName !== "" &&
                employee.lastName !== null &&
                employee.email !== "" &&
                employee.email !== null &&
                employee.managerEmail !== "" &&
                employee.managerEmail !== null &&
                employee.industryYears !== "" && 
                employee.industryYears !== null && 
                employee.employeeTerritory !== "" &&
                employee.employeeTerritory !== null 

            } func={submitContactInfo}>Submit</ModularButton>
            </div>
        </section>
    )
}

export default ContactPage