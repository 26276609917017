import {Link} from 'react-router-dom'
import '../../index.css'
import "./ErrorPage.css"

export default function Error(){
    return(
        <>
            <div className="page-container">
                <h2 className='error-title'>404 ERROR. This Page doesn't Exist</h2>
                <Link style={{fontWeight: "700"}} to="/">Back to Homepage</Link>
            </div>
        </>
    )
}