import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    season: {_id: ""
    }
}

export const seasonSlice = createSlice({
    name: "season",
    initialState,
    reducers:{
        replicateSeason: (state, action)=>{
            state.season = action.payload
        }
    }
})

export const {
    replicateSeason
} = seasonSlice.actions

export default seasonSlice.reducer