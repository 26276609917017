import '../../index.css'
import './entryPageStyles.css'
import { useState } from 'react'
import { emailFormatter } from '../../utils/helperFunctions'
import { getEmployeeByEmail } from '../../dataServices/employees'
import { getCompanyByDomain } from '../../dataServices/companies'
import { getCurrentSeason } from '../../dataServices/seasons'
import { getAllClinics } from '../../dataServices/lessonlyClinics'

import { useDispatch } from 'react-redux'
import { replicateEmployee, emailChange } from '../../store/slices/employeeSlice'
import { replicateCompany } from '../../store/slices/companySlice'
import { replicateSeason } from '../../store/slices/seasonSlice'
import { clinicListReplacement } from '../../store/slices/lessonlyClinicSlice'
import { useNavigate } from 'react-router-dom'

import ModularInput from '../../components/ModularInput/ModularInput'
import ModularButton from '../../components/ModularButton/ModularButton'
import ErrorComponent from '../../components/Error/ErrorComponent'
import Loader from '../../components/Loader/Loader'

const EntryPage =()=>{
    const [initialValue, setInitialValue] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    function setEmail(value){
        setErrorMessage("")
        setInitialValue(emailFormatter(value))
    }
    async function verifyCompany(){
        setIsLoading(true)
        try{
            const result = await getCompanyByDomain(initialValue)
            if(result){
                dispatch(replicateCompany(result))
                try{
                    await storeEmployee()
                }catch(e){
                    alert(e)
                    setIsLoading(false)
                }
            }
        }catch(e){
            setErrorMessage(e.message)
            setIsLoading(false)
        }
    }


    async function storeEmployee(){
        try{

            const result = await getEmployeeByEmail(initialValue)
            if(result){
                if(result.length>0){
                    dispatch(replicateEmployee(result[0]))
                } else{
                    dispatch(emailChange(initialValue))
                }
                try{
                    await retrieveSeason()
                }catch(e){
                    alert(e)
                    setIsLoading(false)

                }
            }
        }catch(e){
            alert(e)
            setIsLoading(false)

        }
    }

    async function retrieveSeason(){
        try{
            const result =  await getCurrentSeason()
            if(result){
                dispatch(replicateSeason(result))
                try{
                    await retrieveClinics()
                }catch(e){
                    alert(e)
                    setIsLoading(false)

                }
            }
        }catch(e){
            alert(e)
            setIsLoading(false)

        }
    }

    async function retrieveClinics(){
        try{
            const result =  await getAllClinics()
            if(result){
                dispatch(clinicListReplacement(result))
                setIsLoading(false)
                navigate('contactpage')
            }
        }catch(e){
            alert(e)
            setIsLoading(false)

        }
    }

    function resetAfterError(){
        setInitialValue('')
        setIsLoading(false)
        setErrorMessage("")
    }


    return(
        <section className="page-container">
            <h1 className="page-title">Enter Your Company Email</h1>
            <ModularInput type="email" value={initialValue} setter={e=>setEmail(e)} marginBottom={'2vmax'} placeHolder={"Email"}/>
            <ModularButton visibility={initialValue !== "" && !isLoading && errorMessage === ""} func={()=>verifyCompany()} >submit</ModularButton>
            {isLoading && <Loader/>}
            <ErrorComponent message={errorMessage} visiblity={errorMessage !== "" && initialValue !== ""} func={resetAfterError}/>
        </section>
    )
}

export default EntryPage