import {
    CompetencyContainer, 
    CardContainer, 
    GameChangerList, 
    CompetencyList
} from "./competencyBoardStyles"
import CardSet from "../CardSet/CardSet"
import { useContext } from 'react'
import { CardContext } from '../../context/CardContext'

function CompetencyBoard(){
    const {mainDeck} = useContext(CardContext)

    return(
        <CompetencyContainer>
            
        
        <CardContainer >
           

                <GameChangerList>
                    <h3 >Game Changer Cards</h3>
                    {
                        mainDeck.map(entry =>{
                            if(mainDeck.indexOf(entry) < 2){
                                
                                return(
                                    <CardSet 
                                        key={mainDeck.indexOf(entry)} 
                                        entry={entry}
                                        bg_Color={entry.learningPath} 
                                        skill={entry.skill} 
                                        devStage={entry.devStage} 
                                        val_={entry.value}
                                        ind_={mainDeck.indexOf(entry)}
                                    />
                                    )
                                    } else return undefined
                                })
                            }
                </GameChangerList>
                
                <CompetencyList>
                    <h3 >Competency Cards</h3>
                    {
                        mainDeck.map(entry =>{
                            if(mainDeck.indexOf(entry) > 1){
                                return(
                                    <CardSet 
                                        key={mainDeck.indexOf(entry)} 
                                        entry={entry}
                                        bg_Color={entry.learningPath} 
                                        skill={entry.skill} 
                                        devStage={entry.devStage} 
                                        dev_={entry.value} 
                                        val_={entry.value}
                                        ind_={mainDeck.indexOf(entry)}
                                    />
                                    )
                                } else return undefined
                            })
                        }
                </CompetencyList>
        
        </CardContainer>

        </CompetencyContainer>
    )
}

export default CompetencyBoard