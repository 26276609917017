import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    popUp:{
        skillPopUp: false,
        currentPopUp: 0
    }
}

export const popUpSlice = createSlice({
    name: "popUp",
    initialState,
    reducers:{
        setSkillPopUp: (state, action)=>{
            state.popUp.skillPopUp = action.payload
        },
        setCurrentPopUp: (state, action)=>{
            state.popUp.currentPopUp = action.payload
        }
    }
})

export const {
    setSkillPopUp,
    setCurrentPopUp
} = popUpSlice.actions

export default popUpSlice.reducer