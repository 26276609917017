import axios from 'axios'
import { url } from '../utils/urlReferences'

export async function getAllClinics(){

    try{
        const response = await axios.get(`${url}/clinics/getall`)
        if(response){
            return response.data
        }
    } catch(e){
        if(e.status === 400){
            throw new Error(e.response.data)
        } else{
            return e
        }
    }
}
