import axios from 'axios'
import { url } from '../utils/urlReferences'


export async function getEmployeeByEmail(email){
 
    try{
        const response = await axios.post(`${url}/employees/email`, {email: email})
        if(response){
            return response.data
        }
    } catch(e){
        return e
    }
}

export async function postAndUpdateEmployee(contactInfo){
 
    try{
        const response = await axios.put(`${url}/employees/editemployee`, contactInfo)
        if(response){
            return response.data
        }
    } catch(e){
        return e
    }
}