import '../../index.css'
import './selectionPageStyles.css'
import SelectionModule from '../../components/Selection/SelectionModule'

const SelectionPage = () =>{
    return(
        <div className="page-container scroll-limit">
            <h1 className='page-title'>Select Your 5 Clinic Topics</h1>
            <div className='selection-module-container'>
                <SelectionModule />
            </div>
        </div>
    )
}

export default SelectionPage