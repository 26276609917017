import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employee: {_id: "",
        firstName: "",
        lastName: "",
        email: "",
        managerEmail: "",
        employeeTerritory: "",
        industryYears: "",
        phoneNumber: "",
        company_id: "",
        producer: false,
        verified: false}
}

export const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers:{
        replicateEmployee: (state, action)=>{
            state.employee = action.payload
        },
        employeeIdChange: (state, action)=>{
            state.employee._id = action.payload
        },
        firstNameChange:(state, action)=>{
            state.employee.firstName = action.payload
        },
        lastNameChange:(state, action)=>{
            state.employee.lastName = action.payload
        },
        emailChange:(state, action)=>{
            state.employee.email = action.payload
        },
        managerEmailChange:(state, action)=>{
            state.employee.managerEmail = action.payload
        },
        employeeTerritoryChange:(state, action)=>{
            state.employee.employeeTerritory = action.payload
        },
        industryYearsChange:(state, action)=>{
            state.employee.industryYears = action.payload
        },
        phoneNumberChange:(state, action)=>{
            state.employee.phoneNumber = action.payload
        },

        company_idChange:(state, action)=>{
            state.employee.company_id = action.payload
        },
        producerChange:(state, action)=>{
            state.employee.producer = action.payload
        },
        verifiedChange:(state, action)=>{
            state.employee.verified = action.payload
        },
    }
})

export const {
    replicateEmployee,
    employeeIdChange,
    firstNameChange, 
    lastNameChange,
    emailChange,
    managerEmailChange,
    employeeTerritoryChange,
    industryYearsChange,
    phoneNumberChange,
    company_idChange,
    producerChange,
    verifiedChange
} = employeeSlice.actions

export default employeeSlice.reducer