import { configureStore } from "@reduxjs/toolkit"
import employeeReducer from './slices/employeeSlice'
import companyReducer from './slices/companySlice'
import hbContactReducer from './slices/hubspotSlice'
import assessmentReducer from './slices/assessmentSlice'
import seasonReducer from './slices/seasonSlice'
import clinicReducer from './slices/lessonlyClinicSlice'
import cardReducer from './slices/cardSlice'
import popUpReducer from './slices/popUpSlice'

export const store = configureStore({
    reducer:{
        employeeState: employeeReducer,
        companyState:  companyReducer,
        hbContactState:  hbContactReducer,
        assessmentState: assessmentReducer,
        seasonState: seasonReducer,
        clinicState: clinicReducer,
        cardState: cardReducer,
        popUpState: popUpReducer
    }
})