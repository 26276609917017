import '../../index.css'
import './confirmationPageStyles.css'
import { useSelector } from 'react-redux'


const ConfirmationPage = () =>{
  const employee = useSelector(state => state.employeeState.employee)

    return(
        <section className='page-container'>
          <div className='confirm-page-container'>
            <p >Your selections have been submitted. You will receive a confirmation email at {employee.email}. You can close this window at anytime.</p>
          </div>
        </section>
    )
}

export default ConfirmationPage