import { useState, useRef } from 'react';
import SelectionSlide from './SelectionSlide'
import './selectionStyles.css'
import {useSelector } from 'react-redux'

const SelectionModule = () =>{
    const clinics = useSelector(state => state.clinicState.lessonly.clinics)
    const myRef = useRef();

    const [currentSelection, setCurrentSelection] = useState(2)

    function determineSlidePosition(monthNumber){
        switch(monthNumber){
            case 2: myRef.current.className="selection-module second-position"; setCurrentSelection(4);
            break;
            case 4: myRef.current.className="selection-module third-position" ; setCurrentSelection(6);
            break;
            case 6: myRef.current.className="selection-module fourth-position" ; setCurrentSelection(8);
            break;
            case 8: myRef.current.className="selection-module fifth-position" ; setCurrentSelection(10);
            break;
            default: myRef.current.className="selection-module";
            break;
        }
    }
    
    return(
        //the monthNumber prop of the SelectionSlides
        //currently works with simply passing a number manually
        //this works for one company, but if this system was
        //to be rewritten for multiple companies, a more dynamic
        //method may be needed to give each slide the corresponding choice
        <div className='selection-module' ref={myRef}>
            <SelectionSlide heading={"Topic 1"} monthNumber={2} selectedNumber={currentSelection} targetArray={clinics} slideFunc={determineSlidePosition}/>
            <SelectionSlide heading={"Topic 2"} monthNumber={4} selectedNumber={currentSelection} targetArray={clinics} slideFunc={determineSlidePosition}/>
            <SelectionSlide heading={"Topic 3"} monthNumber={6} selectedNumber={currentSelection} targetArray={clinics} slideFunc={determineSlidePosition}/>
            <SelectionSlide heading={"Topic 4"} monthNumber={8} selectedNumber={currentSelection} targetArray={clinics} slideFunc={determineSlidePosition}/>
            <SelectionSlide heading={"Topic 5"} monthNumber={10} selectedNumber={currentSelection} targetArray={clinics} />
        </div>
    )
}

export default SelectionModule