import styled, {css} from 'styled-components'

export const OverContainer = styled.div`
    
    
`

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* border: ${props =>  `1px solid ${props.accentcolor}`}; */
    box-shadow: 0px 0px 5px ${props => props.accentcolor};
    background: ${props => props.bottomcolor};
    transition: width 0.5s ease;
    input::-webkit-input-placeholder {
            font-size: 2vmax;
            color: ${props => props.titlecolor};
            text-shadow: 0 0 0.75px black;

            /* transition: padding 0.75s ease;   */
    }
    width: 90vw;
    margin: 0vh auto 0vh auto;
    border-radius: 10px;
    overflow-x: hidden;
    height: 60vh;
    padding: 1rem;

    *{
        color: ${props => props.titlecolor}
    }

    @media (min-aspect-ratio: 9/16){
        width: 65vw;
        font-size: 1.5vmax;
        input::-webkit-input-placeholder {
            font-size: 1.5vmax;
        }
    }

    @media (min-aspect-ratio: 1/1){
        width: 35vw;
        font-size: 2.5vmin;
    }

    
`

const valuePosition = (value) =>{
    let val
    switch(value){
        case 1: val = "50%"
        break
        case 2: val = "25%"
        break
        case 3: val = "0%"
        break
        case 4: val = "-25%"
        break
        default: val = "-25%"
    }

    return val
}

const colorSwapper = (propColor) =>{
    let val
    switch(propColor){
        case "blue": val = `linear-gradient(to right, dodgerblue 90%, #1769bd)`
        break
        case "red": val = `linear-gradient(to right, crimson 90%, #a10b26)`
        break
        case "orange": val = `tomato`
        break
        case "green": val = `forestgreen`
        break
        default: val = "white"
    }
    return val
}




export const SkillReselect = styled.div`
  
`

export const CardTray = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    min-height: 145px;
    position: relative;
    justify-content: space-around;
`

export const GameChangerList = styled.div`

`

export const CompetencyList = styled.div`
    margin-bottom: 2rem;
`

const cardSelectStyles = css`
   
`
export const CardSkillInput = styled.input`
    
    display: block;
    @media (max-width: 300px){
        font-size: 0.6rem;
        padding: 0;
    }
    cursor: pointer;
    padding: 0.5rem 0.35rem;
    padding-left: 1vmax;
    border: none;
    border-bottom: ${props => `0.5px solid ${props.titlecolor}`};

    font-size: 16px;

    /* background-image: linear-gradient( azure , #11898A) ; */
    margin: 0;

    width: 100%;
    

    @media(min-width: 650px){
        font-size: 0.85rem;
    }

    @media (min-width: 800px){
        font-size: 1rem;
        
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.3rem;

    }  
    
    @media (min-aspect-ratio: 9/16){
        font-size: 1.5vmax;
    }

    @media (min-aspect-ratio: 1/1){
        font-size: 3vmin;
        padding-left: 1.5vmin;

    }

    margin-bottom: 1rem;
`

export const ResultsContainer = styled.div`
    display: ${props => props.vis? "block": "none"};
    position: absolute; 
    background: rgba(0,0,0,0.5); 
    height: 100vh;
    width: 100vw; 
    z-index: 2;
    
`
export const ResultsModal = styled.div`
    /* position: absolute; */
    box-shadow: 0px 0px 10px black;
    background-image: linear-gradient(to top, #11898A, white);
    min-height: 50%;
    width: 75%;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    border-radius: 10px;
    overflow-x: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    padding: 1rem;
    opacity: ${props => props.expand? "0" : "1"};
    transition: all 1s ease;
`

export const ResultsCard = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.bg_color};
    border: 1px solid grey;
    width: 125px;
    min-height: 175px;
    border-radius: 10px;
    transform: ${props => props.status? "translateX(0rem) rotateY(0deg)" : "translateX(-30rem) rotateY(-359deg)" };
    transition: 0.75s ease;
    animation: ${props => props.anim? "spin 5s infinite linear" : "none"};
    @keyframes spin{
        0%{
            transform: rotateY(0deg); 
        }
        100%{
            transform: rotateY(359deg);
        }
        

        
    }

    div{
        animation: ${props => props.anim? "fadeColor 5s infinite linear" : "none"};

        @keyframes fadeColor{
            0%{
                color: black;
                background: white;
            }

            24%{
                color: black;
                background: white;
            }

            25%{
                color: transparent;
                background: transparent;
            }


            75%{
                color: transparent;
                background: transparent;
            }

            80%{
                color: black;
                background: white;
            }

            100%{
                color: black;
                background: white;
            }

            
        }
       
    }

   
    margin-bottom: 1rem;
    p{
        margin: 0;
        padding: 0;
    }


   
`

export const PathTip = styled.p`
    font-weight: 400;
`


export const LearningPathSelect = styled.select`
    cursor: pointer;
    background: transparent;
    border: ${props => props.expand? "none" : "1px solid black"};
    border-radius: 3px;
    margin-bottom: 1rem;
    width: ${props => props.expand? "0px" : "initial"};
    transition: all 1s ease;
    option{
        background: rgba(129,192,194,0.6);
        color: black;
        
    }
`

export const ConfirmationButton = styled.button`
    cursor: pointer;
    padding: 0.25rem 1rem;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 700;
    background-image: linear-gradient( azure , #11898A) ;
    margin: 0;
`


export const CardSubmitButton = styled.button`
    cursor: pointer;
    display: block;
    transition: opacity 0.25s ease;
    background: none;
    border: 2px solid black;
    border-radius: 5px;
    width: 100px;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    h4{
        margin: 0;
        padding: 0;
    }

    &:disabled{
        cursor: initial;
    }
`

export const CardConcealableOption = styled.div`
    display: ${props => props.vis? "none" : "block"}; 
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.5rem;
    padding: 0.25rem;
    
    @media (min-width: 800px){
        font-size: 0.7rem;
        font-weight: 400;
    }

    @media (min-width: 1200px){
        font-size: 1.1rem;
     }
     border-bottom: 0.5px solid ${props=>props.titlecolor};
     cursor: pointer;
     /* &:hover{
        background: lightgrey;
     }
     &:active{
        background: hotpink;
     } */

    @media (min-aspect-ratio: 9/16){
        font-size: 1.25vmax;
    }
        
    @media (min-aspect-ratio: 1/1){
        font-size: 2.5vmin;
    }  

`



export const CardSearchListContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
`

export const StyledItem = styled.div`
    padding: 0.25rem;
    color: white;
    border-radius: 10px;
    font-size: 0.65rem;
    
`