import { BallTriangle } from "react-loader-spinner"

const Loader = ({visibility}) =>{
    return(
        <BallTriangle
        height={75}
        width={75}
        radius={5}
        color="#0172CF"
        ariaLabel="ball-triangle-loading"
        wrapperStyle={{}}
        wrapperClass="loader-container"
        visible={true}
        />
    )
}

export default Loader