import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assessment: {
        employee_id: "",
        company_id: "",
        season_id: "",
        clinicList:[]
    }
}

export const assessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers:{
        assessmentEmployeeChange:(state, action)=>{
            state.assessment.employee_id = action.payload
        },
        assessmentCompanyChange:(state, action)=>{
            state.assessment.company_id = action.payload
        },
        assessmentSeasonChange:(state, action)=>{
            state.assessment.employee_id = action.payload
        },
        assessmentClinicListChange:(state, action)=>{
            state.assessment.clinicList = action.payload
        }
    }
})

export const {
    assessmentEmployeeChange,
    assessmentCompanyChange,
    assessmentSeasonChange,
    assessmentClinicListChange
} = assessmentSlice.actions

export default assessmentSlice.reducer

