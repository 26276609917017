import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lessonly: {
        clinics:[]
    }
}

export const clinicSlice = createSlice({
    name: "clinic",
    initialState,
    reducers:{
        clinicListReplacement:(state, action)=>{
            state.lessonly.clinics = action.payload
        }
    }
})

export const {
    clinicListReplacement
} = clinicSlice.actions

export default clinicSlice.reducer