import './pathPageStyles.css'
import '../../index.css'
import ModularButton from '../../components/ModularButton/ModularButton'
import { useNavigate } from 'react-router-dom'

const PathPage =()=>{
    const navigate = useNavigate()

    function navigateToCards(){
        navigate("/gameboardpage")
    }

    function navigateToSelection(){
        navigate("/selectionpage")
    }

    return(
        <div className='page-container'>
                <h1 className='page-title path-title'>
                    Do you want to upload a competency card sort? 
                </h1>
            <div className='path-container'>
                <div className='binary-container'>
                    <ModularButton visibility={true} classAddition={'path-button'} func={navigateToCards}>Yes</ModularButton>
                    <ModularButton visibility={true} classAddition={'path-button'} func={navigateToSelection}>No</ModularButton>
                </div>
            </div>
        </div>
    )
}

export default PathPage