import axios from 'axios'
import { url } from '../utils/urlReferences'

export async function sendAssessment(dataObject){

    try{
        const response = await axios.post(`${url}/assessments/domain `, dataObject)
        if(response){
            return response.data
        }
    } catch(e){
        if(e.status === 400){
            throw new Error(e.response.data)
        } else{
            return e
        }
    }
}

export async function postAndEditAssessment(dataObject){

    try{
        const response = await axios.put(`${url}/assessments/edit `, dataObject)
        if(response){
            return response.data
        }
    } catch(e){
        if(e.status === 400){
            throw new Error(e.response.data)
        } else{
            return e
        }
    }
}